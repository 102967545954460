import PageHeading from '../components/PageHeadings';
import Container from '../components/Container';
import SearchComponent from '../components/SearchComponent';
import { useParams } from 'react-router';

export default function SearchView() {
  let { searchTerm } = useParams();

  return (
    <div className="mb-auto lg:bg-ow-lighter-grey">
      <Container>
        <div className="lg:flex lg:justify-center lg:w-full">
          <div className="lg:w-full lg:bg-white lg:p-8">
            <div className="mb-4">
              <PageHeading title="Search Results" />
            </div>
            <div>
              <SearchComponent searchTerm={searchTerm ?? ''} bulkMode={false} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
