import { useLocation } from 'react-router-dom';

export default function PageHeading({ title, text = '', white = false }: { title: string; text?: string; white?: boolean }) {
  const location = useLocation();
  const isLogin = location.pathname === '/login' || location.pathname === '/forgot-password';

  return (
    <div className={`text-center pt-8 lg:pt-0 lg:text-left ${white ? 'text-white lg:text-black' : ''} ${isLogin ? '!text-white' : ''}`}>
      <h1
        className={`font-mmc text-4xl mb-4 ${white ? 'text-white lg:text-ow-primary' : 'text-ow-primary'} ${isLogin ? '!text-white' : ''}`}
      >
        {title}
      </h1>
      <p className="text-xs px-6 mb-6 lg:px-0">{text}</p>
    </div>
  );
}

// This duplicates the above, but with smaller text
export function SecondaryPageHeading({ title, text = '', white = false }: { title: string; text?: string; white?: boolean }) {
  const location = useLocation();
  const isLogin = location.pathname === '/login' || location.pathname === '/forgot-password';

  return (
    <div className={`text-center lg:text-left ${white ? 'text-white lg:text-black' : ''} ${isLogin ? '!text-white' : ''}`}>
      <h1
        className={`font-mmc text-3xl mb-4 ${white ? 'text-white lg:text-ow-primary' : 'text-ow-primary'} ${isLogin ? '!text-white' : ''}`}
      >
        {title}
      </h1>
      <p className="text-xs px-6 mb-6 lg:px-0">{text}</p>
    </div>
  );
}
