import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { isOktaEnabled } from '../utils/config';
import SearchBox from '../components/SearchBox';
import BackBar from '../components/BackBar';
import { searchBoxInputAtom } from '../store/search-term';
import useApiCaller from '../hooks/use-api-caller';
import OktaAuth from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

export default function Header() {
  const location = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const apiCaller = useApiCaller();
  const oktaEnabled = isOktaEnabled();
  // One of the rules of hooks is don't do them conditionally.
  // Here we brazenly break that rule, laughing.
  /* eslint-disable react-hooks/rules-of-hooks */
  const oktaAuth: OktaAuth | null = oktaEnabled ? useOktaAuth().oktaAuth : null;
  /* eslint-enable react-hooks/rules-of-hooks*/

  const navItems = [
    { text: 'Portfolio', link: '/', icon: 'portfolio', visibleModile: true },
    { text: 'Settings', link: '/settings', icon: 'settings', visibleModile: true },
    { text: 'About', link: '/about', icon: 'help', visibleModile: true },
    { text: 'Contact', link: '/contact', icon: 'contact', visibleModile: true },
  ];

  const isLogin = location.pathname === '/login' || location.pathname === '/forgot-password';
  const isSearch = location.pathname?.includes('/search') ?? false;
  const setSearchBoxInput = useSetRecoilState(searchBoxInputAtom);

  const logoSrc = isLogin ? '/icons/Logo_FSS-Horizontal-white_RGB.svg' : '/icons/Logo_FSS-Horizontal-dark bgd_RGB.svg';

  const defaultLinkClasses = 'inline-block pt-8 pb-3 mb-5 ml-7 hover:text-ow-light-blue focus:text-ow-light-blue';
  const getLinkClasses = (navData: { isActive: boolean }) =>
    navData.isActive ? `${defaultLinkClasses} border-b-2 text-ow-light-blue !border-ow-light-blue` : `${defaultLinkClasses} text-white`;

  const NavItem = ({ text, icon }: { text: string; icon: string }) => {
    return (
      <span className="flex justify-between">
        {text}
        <img src={`/icons/${icon}.svg`} alt={icon} className="w-4" />
      </span>
    );
  };

  const handleLogout = oktaEnabled
    ? () => {
        oktaAuth?.signOut();
        localStorage.clear();
        setIsNavOpen(false);
        apiCaller.logout();
      }
    : () => {};
  // put in className to debug different sizings xl:bg-ow-darkbg-grey lg:bg-ow-red md:bg-ow-orange sm:bg-ow-yellow bg-ow-light-green

  useEffect(() => {
    handlePathChange(location.pathname);
  }, [location.pathname]);

  const handlePathChange = (pathname: string) => {
    if (isSearch) {
      return;
    }

    setSearchBoxInput('');
  };

  return (
    <header className={`relative ${isLogin ? '!bg-ow-primary border-none' : 'bg-black border-b border-black'}`}>
      <div className="p-6 py-2 md:py-0 flex justify-between items-center container mx-auto">
        <NavLink to="/" className="py-2 md:py-6">
          <img src={logoSrc} alt="Oliver Wyman and Dow Jones logo" className="block h-10 md:h-14" />
        </NavLink>

        {!isLogin && (
          <div className="flex items-center justify-end h-10 md:h-14 w-3/4">
            {/* Either search box or search icon visible */}
            {/* Search box visible only on xl, lg, md, sm screens */}
            <div className="hidden sm:inline block block text-xs pl-7 w-[260px]">
              <SearchBox pathname={location?.pathname ?? ''} overrideClickFunction={() => {}} />
            </div>
            {/* Search icon visible only on xs screen */}
            <div className="inline sm:hidden p-2 cursor-pointer" onClick={() => setIsNavOpen(true)}>
              <img src="/icons/search.svg" alt="Menu icon" />
            </div>
            {/* Either hamburger or horizontal menu visible */}
            {/* Hamburger menu visible on md, sm, and xs screens */}
            <div className="lg:hidden p-2 cursor-pointer" onClick={() => setIsNavOpen(true)}>
              <img src="/icons/menu-white.svg" alt="Menu icon" />
            </div>
            {/* horizontal menu visible on lg and xl screens */}
            <nav className="hidden lg:block uppercase font-bold text-xs">
              <ul className="flex">
                {navItems.map((item, index) => (
                  <li key={index}>
                    <NavLink to={item.link} className={getLinkClasses} style={{ textDecoration: 'None' }}>
                      {item.text}
                    </NavLink>
                  </li>
                ))}
                {oktaEnabled ? (
                  <li>
                    <span className="inline-block pt-8 pb-3 ml-7 cursor-pointer text-white hover:text-ow-light-blue" onClick={handleLogout}>
                      Logout
                    </span>
                  </li>
                ) : (
                  <li></li>
                )}
              </ul>
            </nav>
          </div>
        )}
      </div>

      {/* Drop down menu in xs screen size */}
      {isNavOpen && (
        <div>
          <div onClick={() => setIsNavOpen(false)} className="fixed top-0 bottom-0 left-0 right-0 z-20"></div>
          <nav className="bg-black text-white fixed top-0 left-0 right-0 z-30 shadow-lg lg:hidden">
            <div className="container mx-auto p-6 pb-2">
              <div className="flex justify-between mb-6">
                <div className="py-2 w-2/3">
                  <img
                    src="/icons/Logo_FSS-Horizontal-dark bgd_RGB.svg"
                    alt="Oliver Wyman and Dow Jones logo"
                    className="block h-10 md:h-14"
                  />
                </div>
                <div className="p-2 cursor-pointer" onClick={() => setIsNavOpen(false)}>
                  <img src="/icons/close.svg" alt="Close" />
                </div>
              </div>
              <div className="block block text-xs text-black">
                <SearchBox pathname={location?.pathname ?? ''} overrideClickFunction={() => setIsNavOpen(false)} />
              </div>

              <ul className="font-mmc py-3">
                {navItems
                  .filter((item) => item.visibleModile)
                  .map((item, index) => (
                    <li key={index} className={`${index > 0 && 'border-ow-light-blue border-t'}`}>
                      <NavLink to={item.link} className="block py-3 text-white" onClick={() => setIsNavOpen(false)}>
                        <NavItem text={item.text} icon={item.icon} />
                      </NavLink>
                    </li>
                  ))}
                {oktaEnabled ? (
                  <li className="border-ow-light-blue border-t block py-3" onClick={handleLogout}>
                    <NavItem text="Logout" icon="logout" />
                  </li>
                ) : (
                  <li></li>
                )}
              </ul>
            </div>
          </nav>
        </div>
      )}
      {!isLogin && <BackBar />}
    </header>
  );
}
