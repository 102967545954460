import { useEffect, useState } from 'react';
import { MultiValue } from 'react-select';
import { NavLink } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import PageHeading from '../components/PageHeadings';
import Container from '../components/Container';
import ICompanySummary from '../types/company';
import useApiCaller from '../hooks/use-api-caller';
import Loading from '../components/Loading';
import PortfolioList from '../components/PortfolioList';
import useCurrentPage from '../hooks/use-set-current-page';
import FSSError from '../components/FSSError';
import { portfolioCompaniesListAtom } from '../store/portfolio';
import { addSortFieldToCompanies } from '../utils/add-sort-field-to-companies';
import ComparePercent from '../components/ComparePercent';
import CompareHistory from '../components/CompareHistory';
import {
  assignUserList,
  convertFromValueLabel,
  convertToValueLabel,
  getUniqueCountries,
  getUniqueIndustries,
} from '../utils/compare-functions';
import MultiSelectDropdown from '../components/MultiSelect';

export default function Compare() {
  const apiCaller = useApiCaller();
  const currentPage = useCurrentPage();
  useEffect(() => {
    currentPage.setCurrentPage('Portfolio');
  }, []);

  useEffect(() => {
    fetchPortfolio();
  }, []);

  const compareFeature = true;
  const [loadingPortfolio, setLoadingPortfolio] = useState(false);
  const [portfolioCompaniesList, setPortfolioCompaniesList] = useRecoilState(portfolioCompaniesListAtom);
  const [portfolioError, setPortfolioError] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState<ICompanySummary[]>([]);
  const [countriesValueLabel, setCountriesValueLabel] = useState<MultiValue<{ value: string; label: string }>>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [industriesValueLabel, setIndustriesValueLabel] = useState<MultiValue<{ value: string; label: string }>>([]);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [userListsValueLabel, setUserListsValueLabel] = useState<MultiValue<{ value: string; label: string }>>([]);
  const [selectedUserLists, setSelectedUserLists] = useState<string[]>([]);

  const fetchPortfolio = () => {
    if (loadingPortfolio || !!portfolioCompaniesList.length) return;

    setLoadingPortfolio(true);

    apiCaller
      .getPortfolio()
      .then((res) => {
        const sortableCompanies = assignUserList(addSortFieldToCompanies(res.items));
        setPortfolioCompaniesList(sortableCompanies);

        if (true) {
          setSelectedCompanies(sortableCompanies);
          setCountriesValueLabel(convertToValueLabel(getUniqueCountries(sortableCompanies)));
          setIndustriesValueLabel(convertToValueLabel(getUniqueIndustries(sortableCompanies)));
          setUserListsValueLabel(convertToValueLabel(['List 1', 'List 2']));
        }
      })
      .catch((err) => {
        console.error(err);
        setPortfolioError(true);
      })
      .finally(() => {
        setLoadingPortfolio(false);
      });
  };

  useEffect(() => {
    const filteredCompanies = portfolioCompaniesList
      .filter((company) => (selectedCountries.length > 0 ? selectedCountries.includes(company.country) : true))
      .filter((company) => (selectedIndustries.length > 0 ? selectedIndustries.includes(company.industry_sector) : true))
      .filter((company) => (selectedUserLists.length > 0 ? selectedUserLists.includes(company.userList ?? '') : true));
    setSelectedCompanies(filteredCompanies);
  }, [selectedCountries, selectedIndustries, selectedUserLists]);

  const onCountrySelectionChange = (selectedValueLabel: MultiValue<{ value: string; label: string }>) => {
    setSelectedCountries(convertFromValueLabel(selectedValueLabel));
  };

  const onIndustrySelectionChange = (selectedValueLabel: MultiValue<{ value: string; label: string }>) => {
    setSelectedIndustries(convertFromValueLabel(selectedValueLabel));
  };

  const onUserListSelectionChange = (selectedValueLabel: MultiValue<{ value: string; label: string }>) => {
    setSelectedUserLists(convertFromValueLabel(selectedValueLabel));
  };

  return (
    <div className="mb-auto lg:bg-ow-lighter-grey">
      <Container>
        <div className="lg:flex lg:justify-center lg:w-full">
          <div className="lg:w-full lg:bg-white lg:p-8">
            <PageHeading
              title="My companies"
              text="Click on a company name below to view the news and its effect on the company's FSS Score"
            />

            <div className="companies-list-container">
              {compareFeature && (
                <div>
                  <div className="flex">
                    <MultiSelectDropdown options={countriesValueLabel} onSelectionChange={onCountrySelectionChange} />
                    <MultiSelectDropdown options={industriesValueLabel} onSelectionChange={onIndustrySelectionChange} />
                    <MultiSelectDropdown options={userListsValueLabel} onSelectionChange={onUserListSelectionChange} />
                  </div>
                  <ComparePercent selectedCompanies={selectedCompanies} allCompanies={portfolioCompaniesList} />
                  <CompareHistory selectedCompanies={selectedCompanies} />
                </div>
              )}
              <PortfolioList companies={selectedCompanies} />

              {loadingPortfolio && <Loading />}

              {portfolioError && <FSSError />}
              {!loadingPortfolio && !portfolioError && !portfolioCompaniesList.length && (
                <div>
                  <p className="text-xs text-center">No companies to display</p>
                  <p className="text-xs text-center">
                    If you believe this is an error, please get in touch via the{' '}
                    <NavLink to="/contact" className="text-ow-secondary">
                      contact us
                    </NavLink>{' '}
                    page.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
