import { MouseEventHandler } from 'react';

export default function Button({
  text,
  onClick,
  type = 'button',
  disabled = false,
  noMargin = false,
}: {
  text: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit';
  disabled?: boolean;
  noMargin?: boolean;
}) {
  return (
    <div>
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={`w-full bg-ow-secondary text-white font-mmc p-2 disabled:opacity-60 disabled:cursor-not-allowed ${
          noMargin ? '' : 'mb-4'
        }`}
      >
        {text}
      </button>
    </div>
  );
}
