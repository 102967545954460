import _ from 'lodash';
import { MultiValue } from 'react-select';
import ICompanySummary from '../types/company';

export const convertToValueLabel = (myList: string[]): MultiValue<{ value: string; label: string }> => {
  return myList.map((item) => {
    return { value: item, label: item };
  });
};

export const convertFromValueLabel = (myValueLabels: MultiValue<{ value: string; label: string }>): string[] => {
  return myValueLabels.map((item) => {
    return item.value;
  });
};

export const assignUserList = (companies: ICompanySummary[]): ICompanySummary[] => {
  return companies.map((company) => {
    company.userList = company.name.length % 2 ? 'List 1' : 'List 2';
    return company;
  });
};

export const getUniqueCountries = (companies: ICompanySummary[]): string[] => {
  return _.orderBy(Object.keys(_.groupBy(companies, 'country')));
};

export const getUniqueIndustries = (companies: ICompanySummary[]): string[] => {
  return _.orderBy(Object.keys(_.groupBy(companies, 'industry_sector')));
};
