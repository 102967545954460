import Highcharts, { Options } from 'highcharts';
import noDataModule from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import { useRef } from 'react';
import { TooltipOptionsExtended } from '../types/tooltip-data';
import '../styles/CompanyGraphs.scss';
import '../extensions/highcharts-plugins';
import ICompanySummary from '../types/company';
import { black, blue, GRAPH_FONT_SIZE } from '../constants/company-chart';
import { createComparableHistoryCompanies, createComparableHistoryDataSeries } from '../utils/compare-history-functions';

noDataModule(Highcharts);

export default function CompareHistory({
  selectedCompanies,
}: Readonly<{
  selectedCompanies: ICompanySummary[];
}>) {
  const ref = useRef<any>();
  const chartRef = useRef(null);

  const comparableHistoryCompanies = createComparableHistoryCompanies(selectedCompanies);
  const tooltipOptions: TooltipOptionsExtended = {
    useHTML: true,
    borderWidth: 0,
    backgroundColor: 'rgba(255,255,255,0)',
    shadow: false,
    // charts crash without this function
    remainOnMouseout: function () {
      return true;
    },
  };

  const options: Options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      animation: true,
      backgroundColor: 'transparent',
      height: 575,
      spacing: [10, 0, 0, 0],
    },
    credits: {
      enabled: false,
    },
    lang: {
      noData: '<div style="color:#FF0000">No companies match these filter criteria in your portfolio.</div>',
    },
    legend: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    noData: {
      useHTML: true,
    },
    series: createComparableHistoryDataSeries(comparableHistoryCompanies),
    title: { text: '' },
    tooltip: tooltipOptions,
    yAxis: [
      {
        title: {
          text: 'FSS Score (0-100)',
          style: { color: black, fontSize: GRAPH_FONT_SIZE },
        },
        labels: {
          style: { color: black, fontSize: GRAPH_FONT_SIZE },
        },
        min: 0,
        max: 100,
        lineColor: blue,
        lineWidth: 4,
        gridLineWidth: 0,
      },
    ],
  };

  return (
    <div ref={ref}>
      <div className={`flex justify-end sm:justify-between mb-2 mt-2 lg:mt-0`}></div>

      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
    </div>
  );
}
