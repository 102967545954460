import { useState } from 'react';
import ISearchedCompany from '../types/company';
import { NavLink } from 'react-router-dom';
import SearchListHeader from './SearchListHeader';

import SearchListItem from './SearchListItem';

function compareNames(companyA: ISearchedCompany, companyB: ISearchedCompany, sortDirection: string) {
  if (sortDirection === 'DOWN') {
    return companyB.sortable_name.localeCompare(companyA.sortable_name);
  }
  return companyA.sortable_name.localeCompare(companyB.sortable_name);
}

export default function SearchList({
  companies,
  searchTerm,
  bulkMode,
}: Readonly<{
  companies: ISearchedCompany[];
  searchTerm: string;
  bulkMode: boolean;
}>) {
  const bestMatch = bulkMode ? 'Article match' : 'Best match';
  const sortOptions = [
    { value: 'BEST_MATCH', label: bestMatch },
    { value: 'NAME_A_Z', label: 'A-Z' },
    { value: 'NAME_Z_A', label: 'Z-A' },
  ];
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);

  const compareCompanySortOrder = (companyA: ISearchedCompany, companyB: ISearchedCompany) => {
    if (selectedSortOption.value === 'BEST_MATCH') {
      const companyAScore = companyA.score ?? 3000;
      const companyBScore = companyB.score ?? 3000;
      const scoreDifference = companyAScore - companyBScore;
      if (scoreDifference === 0) {
        return compareNames(companyA, companyB, 'UP');
      }
      return scoreDifference;
    } else if (selectedSortOption.value === 'NAME_A_Z') {
      return compareNames(companyA, companyB, 'UP');
    }
    return compareNames(companyA, companyB, 'DOWN');
  };

  return (
    <div>
      <SearchListHeader
        sortOptions={sortOptions}
        selectedSortOption={selectedSortOption}
        setSelectedSortOption={setSelectedSortOption}
        bulkMode={bulkMode}
      />
      {companies.toSorted(compareCompanySortOrder).map((company) => (
        <SearchListItem
          key={company.company_code}
          company_code={company.company_code}
          name={company.name}
          country={company.country}
          in_portfolio={company.in_portfolio}
          searchTerm={searchTerm}
          bulkMode={bulkMode}
        />
      ))}
      <div className="text-xs text-center mt-8">
        <NavLink to="/contact">If you think there’s a company missing that we should be tracking, contact us.</NavLink>{' '}
      </div>
    </div>
  );
}
