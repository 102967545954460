import { useState } from 'react';
import Select, { MultiValue } from 'react-select';

export default function MultiSelectDropdown({
  options,
  onSelectionChange,
}: Readonly<{
  options: MultiValue<{ value: string; label: string }>;
  onSelectionChange: any;
}>) {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<{ value: string; label: string }>>([]);

  const handleChange = (selected: MultiValue<{ value: string; label: string }>) => {
    setSelectedOptions(selected);
    onSelectionChange(selected);
  };

  return (
    <div style={{ width: '300px' }}>
      <Select isMulti value={selectedOptions} onChange={handleChange} options={options} />
    </div>
  );
}
