import { useEffect } from 'react';

import useCurrentPage from '../hooks/use-set-current-page';
import Container from '../components/Container';
import PageHeading from '../components/PageHeadings';
import EmailDeliverySelector from '../components/EmailDeliverySelector';

export default function Settings() {
  const currentPage = useCurrentPage();
  useEffect(() => {
    currentPage.setCurrentPage('Settings');
  }, []);

  return (
    <Container>
      <div className="lg:flex lg:justify-center lg:w-full">
        <div className="lg:w-full lg:bg-white lg:p-8">
          <PageHeading title="Email Settings" />
          <EmailDeliverySelector></EmailDeliverySelector>
        </div>
      </div>
    </Container>
  );
}
