import Highcharts, { Options } from 'highcharts';
import noDataModule from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import { useRef } from 'react';
import { TooltipOptionsExtended } from '../types/tooltip-data';
import '../styles/CompanyGraphs.scss';
import '../extensions/highcharts-plugins';
import { black, blue, GRAPH_FONT_SIZE } from '../constants/company-chart';
import ICompanySummary from '../types/company';
import {
  createComparablePercentCompanies,
  getPercentChartMinXMaxX,
  createComparablePercentDataSeries,
} from '../utils/compare-percent-functions';

noDataModule(Highcharts);

export default function ComparePercent({
  selectedCompanies,
  allCompanies,
}: Readonly<{
  selectedCompanies: ICompanySummary[];
  allCompanies: ICompanySummary[];
}>) {
  const ref = useRef<any>();
  const chartRef = useRef(null);

  const tooltipOptions: TooltipOptionsExtended = {
    useHTML: true,
    borderWidth: 0,
    backgroundColor: 'rgba(255,255,255,0)',
    shadow: false,
    // charts crash without this function
    remainOnMouseout: function () {
      return false;
    },
  };

  const comparablePercentCompanies = createComparablePercentCompanies(selectedCompanies);
  const [chartMinX, chartMaxX] = getPercentChartMinXMaxX(createComparablePercentCompanies(allCompanies));

  const options: Options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      animation: true,
      backgroundColor: 'transparent',
      height: 575,
      spacing: [10, 0, 0, 0],
      type: 'scatter',
      zooming: {
        type: 'xy',
      },
    },
    credits: {
      enabled: false,
    },
    lang: {
      noData: '<div style="color:#FF0000">No companies in your portfolio with valid scores match these filter criteria.</div>',
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    noData: {
      useHTML: true,
    },
    plotOptions: {
      scatter: {
        marker: { enabled: true, radius: 5, symbol: 'circle' },
        lineWidth: 0,
        dataLabels: {
          align: 'right',
          enabled: true,
          padding: 10,
          formatter: function () {
            return this.point.name;
          },
          verticalAlign: 'middle',
        },
        tooltip: {
          headerFormat: '',
          pointFormat: `
      <div style="background: #fff;border: 1px solid #dadada;min-width: 140px; border-radius: 10px;z-index: 20;">
        <div style="color:#FFF;background: black;padding: 1px;height: 26px;text-align: center;line-height: 26px;border-top-left-radius: 10px;border-top-right-radius: 10px;padding-left:10px;padding-right:10px;">
          {point.name}
        </div>
        <div style="display:flex; justify-content:center;padding:10px;">
          <table >
            <tr >
              <td style="">Weekly %:</td>
              <td style="width: 50px; font-size: 20px;font-weight: 700;line-height: 18px;text-align:right;">{point.x}</td>
            </tr>
            <tr>
              <td style="">Score:</td>
              <td style="width: 50px;color:#009de0; font-size: 20px;font-weight: 700;line-height: 18px;text-align:right;">{point.y}</td>
            </tr>
          </table>
        </div>
      </div>
      `,
        },
      },
    },
    series: createComparablePercentDataSeries(comparablePercentCompanies),
    title: { text: '' },
    tooltip: tooltipOptions,
    xAxis: [
      {
        max: chartMaxX,
        min: chartMinX,
        title: { text: 'Weekly % FSS score change', style: { color: black, fontSize: GRAPH_FONT_SIZE } },
      },
    ],
    yAxis: [
      {
        title: {
          text: 'FSS Score (0-100)',
          style: { color: black, fontSize: GRAPH_FONT_SIZE },
        },
        labels: {
          style: { color: black, fontSize: GRAPH_FONT_SIZE },
        },
        min: 0,
        max: 100,
        lineColor: blue,
        lineWidth: 4,
        gridLineWidth: 0,
      },
    ],
  };

  return (
    <div ref={ref}>
      <div className={`flex justify-end sm:justify-between mb-2 mt-2 lg:mt-0`}></div>

      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
    </div>
  );
}
