import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import useApiCaller from '../hooks/use-api-caller';
import '../styles/PortfolioListItem.scss';

export default function PortfolioListItem({
  name,
  location,
  company_code,
  current_score,
  current_score_text,
  current_score_color_name,
  previous_percent_change,
}: Readonly<{
  name: string;
  location: string;
  company_code: string;
  current_score: number;
  current_score_text: string;
  current_score_color_name: string;
  previous_percent_change: number;
}>) {
  const apiCaller = useApiCaller();

  const [removeButtonClicked, setRemoveButtonClicked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  function reloadPage() {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  function handleRemoveCompanyClick() {
    apiCaller
      .removeFromPortfolio(company_code)
      .then(() => {
        setRemoveButtonClicked(true);
        reloadPage();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getRemovedPortfolioStyle() {
    if (removeButtonClicked) {
      return `rounded-full text-center text-xs min-w-[12rem] py-1 px-3 border border-ow-secondary text-white bg-ow-secondary`;
    }
    return ``;
  }

  function getRemovedPortfolioText() {
    if (removeButtonClicked) {
      return 'REMOVED FROM PORTFOLIO';
    }
    return '';
  }

  function getRemoveImage() {
    if (removeButtonClicked) {
      return '/icons/check.svg';
    }
    return isHovered ? '/icons/remove_selected.svg' : '/icons/remove.svg';
  }

  function getRemoveImageAlt() {
    if (removeButtonClicked) {
      return 'checkImg';
    }
    return 'removeImg';
  }

  function getPercentSign(percent_change: number) {
    return percent_change < 0 ? '' : '+';
  }

  function getPercentDisplay(percent_change: number) {
    const value = Math.round(percent_change * 10) / 10;
    return `${getPercentSign(value)}${value.toFixed(1)}%`;
  }

  return (
    <div>
      <div className="flex justify-between items-center gap-1 mb-2 mt-2">
        <div className="max-w-[50%]">
          <NavLink to={`/company/${company_code}`}>
            <div>
              <div className="mr-4">
                <h3 className="text-ow-secondary fss-company-name">{name}</h3>
                <p className="text-xs fss-company-country">{location}</p>
              </div>
            </div>
          </NavLink>
        </div>
        <div className="flex items-center ">
          <div className={getRemovedPortfolioStyle()}>{getRemovedPortfolioText()}</div>
          <div className="min-w-[3rem] sm:min-w-[5rem] text-center">{Math.round(current_score)}</div>
          <div className="min-w-[3rem] sm:min-w-[5rem] text-center">{getPercentDisplay(previous_percent_change)}</div>
          <div className="min-w-[2rem] sm:min-w-[5.5rem] m-0 pb-0">
            <div className={`rounded-full text-center text-xs  py-1 px-3 text-black bg-${current_score_color_name}`}>
              <div className="hidden sm:inline">{current_score_text}</div>
            </div>
          </div>
          <div className="min-w-[2rem] sm:min-w-[3rem]  m-0 pb-0 py-1 text-right">
            <button
              disabled={removeButtonClicked}
              onClick={() => handleRemoveCompanyClick()}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img src={getRemoveImage()} alt={getRemoveImageAlt()} />
            </button>
          </div>
        </div>
      </div>
      <div className="border-b border-ow-light-grey"></div>
    </div>
  );
}
