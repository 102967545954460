import { useNavigate } from 'react-router-dom';

export default function BackBar() {
  const navigate = useNavigate();

  return (
    <div className="top-0 bg-black">
      <div className="container px-6 mx-auto">
        <div className="flex justify-start text-xs text-ow-light-blue pb-3">
          <button onClick={() => navigate(-1)}>&lt; Back</button>
        </div>
      </div>
    </div>
  );
}
