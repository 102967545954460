import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { ArticleList } from '../components/ArticleList';
import CompanyHeader from '../components/CompanyHeader';
import CompanyGraphs from '../components/CompanyGraphs';
import Container from '../components/Container';
import Loading from '../components/Loading';
import useApiCaller from '../hooks/use-api-caller';
import useCurrentPage from '../hooks/use-set-current-page';
import ICompany from '../types/company';
import { getOrderedTimeseries } from '../utils/order-data';
import { getDowngradeProbability } from '../utils/downgrade-utilities';
import FSSError from '../components/FSSError';
import { displayCustomRangeDialogAtom, pageDateRangeAtom, pageDateRangeOptions } from '../store/timerange';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { companyAtom } from '../store/company';
import { articlesAtom, pageAtom, sortOptionsAtom, themesWithArticlesAtom } from '../store/article';
import { articleListSortOptions } from '../constants/articles-list';

export default function Company() {
  const apiCaller = useApiCaller();
  const { companyId } = useParams();
  const currentPage = useCurrentPage();

  /**
   * Fetch company data
   */
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<ICompany>();
  const [lastCompany, setLastCompany] = useRecoilState(companyAtom);
  const [companyError, setCompanyError] = useState(false);
  const setPageDateRange = useSetRecoilState(pageDateRangeAtom);
  const setArticles = useSetRecoilState(articlesAtom);
  const setThemes = useSetRecoilState(themesWithArticlesAtom);
  const setSelectedSortOption = useSetRecoilState(sortOptionsAtom);
  const setDisplayCustomRangeDialog = useSetRecoilState(displayCustomRangeDialogAtom);
  const setPage = useSetRecoilState(pageAtom);

  const resetCompany = () => {
    // reset date ranges and article/theme lists when company changes
    setPageDateRange(pageDateRangeOptions[0]);
    setDisplayCustomRangeDialog(false);
    setPage(0);
    setArticles([]);
    setThemes([]);
    setSelectedSortOption(articleListSortOptions[0]);
  };

  const fetchCompany = (companyId: string) => {
    setLoading(true);

    apiCaller
      .getCompany(companyId)
      .then((res) => {
        let company = res;
        company.time_series = getOrderedTimeseries(company.time_series);
        setCompany(company);
        setLastCompany(company);
        currentPage.setCurrentPage(`Company - ${res.name} - ${companyId}`);
      })
      .catch((err) => {
        setCompanyError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (companyId) {
      currentPage.setCurrentPage(`Company - ${companyId}`);

      if (lastCompany && lastCompany.company_code !== companyId) {
        resetCompany();
      }

      fetchCompany(companyId);
    }
  }, []);

  /**
   * Sticky feedback
   */
  const articleList = useRef(null);

  /**
   * FSS Score scale modal
   */

  return (
    <div className="mb-auto lg:bg-ow-lighter-grey">
      {!loading && company && (
        <div>
          <CompanyHeader name={company.name || ''} location={company.country || ''} probability={getDowngradeProbability(company)} />
        </div>
      )}
      <div className="lg:container lg:mx-auto">
        <div className="lg:flex lg:justify-center lg:w-full">
          <div className="lg:w-full lg:bg-white lg:pt-8 lg:px-2">
            {companyError && <FSSError />}

            {loading && (
              <div className="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white z-50">
                <Loading />
              </div>
            )}

            {!loading && company && (
              <Container>
                <CompanyGraphs timeseriesData={company.time_series} companyName={company.name} />
              </Container>
            )}

            {companyId && (
              <Container>
                <div ref={articleList} className="pt-8">
                  {company && <ArticleList companyId={companyId} companyName={company.name} />}
                </div>
              </Container>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

/*
SQL used on this page:

-- most recent articles
SELECT
      articles_code.company_code AS articles_code_company_code, 
      articles_code.publication_datetime AS articles_code_publication_datetime, 
      articles_code.title AS articles_code_title, 
      articles_code.source_name AS articles_code_source_name, 
      articles_code.dowj_article_id AS articles_code_dowj_article_id, 
      articles_code.theme_id AS articles_code_theme_id
FROM articles_code
WHERE articles_code.company_code = 'aaafts'  AND articles_code.publication_datetime >= '2018-12-19' AND articles_code.publication_datetime <= '2024-06-19' 
ORDER BY articles_code.publication_datetime DESC, TITLE, SOURCE_NAME
;

-- Most relevant negative articles
SELECT
      articles_code.company_code AS articles_code_company_code, 
      articles_code.publication_datetime AS articles_code_publication_datetime, 
      articles_code.title AS articles_code_title, 
      articles_code.source_name AS articles_code_source_name, 
      articles_code.dowj_article_id AS articles_code_dowj_article_id, 
      articles_code.theme_id AS articles_code_theme_id
FROM articles_code
WHERE articles_code.company_code = 'aaafts'  AND articles_code.publication_datetime >= '2018-12-19' AND articles_code.publication_datetime <= '2024-06-19' 
ORDER BY articles_code.score DESC, TITLE, SOURCE_NAME
;

--  positive articles
SELECT
      articles_code.company_code AS articles_code_company_code, 
      articles_code.publication_datetime AS articles_code_publication_datetime, 
      articles_code.title AS articles_code_title, 
      articles_code.source_name AS articles_code_source_name, 
      articles_code.dowj_article_id AS articles_code_dowj_article_id, 
      articles_code.theme_id AS articles_code_theme_id
FROM articles_code
WHERE articles_code.company_code = 'aaafts'  AND articles_code.publication_datetime >= '2018-12-19' AND articles_code.publication_datetime <= '2024-06-19'  
  AND articles_code.downgrade_flag = 'Positive'
 ORDER BY articles_code.publication_datetime DESC, TITLE, SOURCE_NAME
;

--  negative articles
SELECT
      articles_code.company_code AS articles_code_company_code, 
      articles_code.publication_datetime AS articles_code_publication_datetime, 
      articles_code.title AS articles_code_title, 
      articles_code.source_name AS articles_code_source_name, 
      articles_code.dowj_article_id AS articles_code_dowj_article_id, 
      articles_code.theme_id AS articles_code_theme_id
FROM articles_code
WHERE articles_code.company_code = 'aaafts'  AND articles_code.publication_datetime >= '2018-12-19' AND articles_code.publication_datetime <= '2024-06-19'  
  AND articles_code.downgrade_flag = 'Negative'
 ORDER BY articles_code.publication_datetime DESC, TITLE, SOURCE_NAME
;


-- query used to find and order themes
SELECT DISTINCT themes.id AS themes_id, themes.theme_name AS themes_theme_name 
FROM themes INNER JOIN articles_code ON themes.id = articles_code.theme_id
WHERE articles_code.company_code = 'aaafts' 
ORDER BY theme_downgrade_score DESC, theme_relevancy
;

-- equivalent query that I understand
--  odd that it is not limited by date
select  theme_id
from articles_code
WHERE articles_code.company_code = 'aaafts'
group by theme_id
order by theme_downgrade_score DESC,  theme_relevancy


-- each three items is derived using this query
SELECT 
      articles_code.company_code AS articles_code_company_code, 
      articles_code.publication_datetime AS articles_code_publication_datetime, 
      articles_code.title AS articles_code_title, 
      articles_code.source_name AS articles_code_source_name, 
      articles_code.dowj_article_id AS articles_code_dowj_article_id, 
      articles_code.theme_id AS articles_code_theme_id
FROM articles_code
WHERE articles_code.company_code = 'aaafts'  AND articles_code.publication_datetime >= '2018-12-19' AND articles_code.publication_datetime <= '2024-06-19'  
  AND articles_code.theme_id = 14 
ORDER BY theme_downgrade_score DESC, theme_relevancy, TITLE, SOURCE_NAME
limit 3


drop table articles_code_near_duplicateA;
create table articles_code_near_duplicateA as 
select * from (
select 
  `company_code`,
  date(`publication_datetime`) publication_datetime,
  `title`,
  `source_name`,
  `theme_id`,
  `score`,
  `downgrade_flag`,
  `theme_downgrade_score`,
  `theme_relevancy`,
  dowj_article_id,
  `modification_datetime`,
   row_number() over (
     partition by 
       company_code,
        date(`publication_datetime`),
        title,
       `source_name`
    order by 
       modification_datetime desc,
       dowj_article_id 
    ) near_duplicate_row_number
from   articles_code
where company_code = 'aaafts' 
) aa
where near_duplicate_row_number = 1
order by 1,2,3
  ;
 
drop table articles_code_near_duplicate_source_differentA;
create table articles_code_near_duplicate_source_differentA as 
select *
,  min(dowj_article_id) over (
     partition by 
       company_code,
        date(`publication_datetime`),
        title
    order by 
       modification_datetime desc,
       dowj_article_id 
    ) publication_title_unique_id
,  min(dowj_article_id) over (
     partition by 
       company_code,
       score,
        date(`publication_datetime`),
        title
    order by 
       modification_datetime desc,
       dowj_article_id 
    ) score_publication_title_unique_id
,  min(dowj_article_id) over (
     partition by 
       company_code,
       theme_id,
       theme_downgrade_score,
       theme_relevancy,
        date(`publication_datetime`),
        title
    order by 
       modification_datetime desc,
       dowj_article_id 
    ) theme_publication_title_unique_id
,  row_number() over (
     partition by 
       company_code,
        date(`publication_datetime`),
        title
    order by 
       source_name,
       dowj_article_id 
    ) publication_title_row
,  row_number() over (
     partition by 
       company_code,
       score,
        date(`publication_datetime`),
        title
    order by 
       source_name,
       dowj_article_id 
    ) score_publication_title_row
,  row_number() over (
     partition by 
       company_code,
       theme_id,
       theme_downgrade_score,
       theme_relevancy,
        date(`publication_datetime`),
        title
    order by 
       source_name,
       dowj_article_id 
    ) theme_publication_title_row   

from articles_code_near_duplicateA
order by 1,2,3,4
;
*/
