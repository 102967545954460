import SelectInput from './SelectInput';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  displayCustomRangeDialogAtom,
  pageDateRangeAtom,
  closePopupAtom,
  customID,
  customRange,
  pageDateRangeOptions,
  earliestAllowedCustomDate,
  latestDate as latestAllowedCustomDate,
  dateDisplaySeparator,
} from '../store/timerange';
import { formatISO } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import '../styles/DateFilter.scss';
import { getDateFormatPatternForLocale, fixedNewDate } from '../utils/dates';
import { useEffect, useState } from 'react';

const { allowedRange } = DateRangePicker;

export default function DateFilter() {
  const [displayCustomRangeDialog, setDisplayCustomRangeDialog] = useRecoilState(displayCustomRangeDialogAtom);
  const [pageDateRange, setPageDateRange] = useRecoilState(pageDateRangeAtom);
  const [displayCustomDateRange, setDisplayCustomDateRange] = useState<[Date, Date] | null>();
  const setClosePopup = useSetRecoilState(closePopupAtom);

  const handleTimeRangeChange = (option: { value: string; label: string; earliestDate: string; latestDate: string }) => {
    setPageDateRange(option);

    setDisplayCustomRangeDialog(option.value === 'CUSTOM');
  };

  const handleDateRangePickerOnChange = (myDateRange: any) => {
    const earliestDate = myDateRange ? formatISO(myDateRange[0], { representation: 'date' }) : earliestAllowedCustomDate; // YYYY-MM-DD
    const latestDate = myDateRange ? formatISO(myDateRange[1], { representation: 'date' }) : latestAllowedCustomDate;

    const option = { value: customID, label: customRange, earliestDate: earliestDate, latestDate: latestDate };

    setPageDateRange(option);
  };

  const handleDateRangePickerOnClean = () => {
    setPageDateRange(pageDateRangeOptions[0]);
    setDisplayCustomRangeDialog(false);
    setDisplayCustomDateRange(null);
  };

  const handleDateRangePickerOnOpen = () => {
    setClosePopup(true);
  };

  const handleTimeRangeOpen = () => {
    setClosePopup(true);
  };

  useEffect(() => {
    if (pageDateRange.earliestDate !== earliestAllowedCustomDate && pageDateRange.latestDate !== latestAllowedCustomDate) {
      setDisplayCustomDateRange([fixedNewDate(pageDateRange.earliestDate), fixedNewDate(pageDateRange.latestDate)]);
    }
  }, [pageDateRange]);

  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const selectColor = vw < 1023 ? '#ffffff' : undefined;

  const dateFormatPatternForLocale = getDateFormatPatternForLocale();

  return (
    <div>
      <div className="flex flex-col items-end">
        <div className="flex flex-row items-center" style={{ zIndex: 20 }}>
          {displayCustomRangeDialog && (
            <div className="justify-left mr-1 md:mr-2 lg:mr-3">
              <DateRangePicker
                onChange={handleDateRangePickerOnChange}
                onClean={handleDateRangePickerOnClean}
                onOpen={handleDateRangePickerOnOpen}
                showOneCalendar
                ranges={[]}
                value={displayCustomDateRange}
                format={dateFormatPatternForLocale}
                placeholder="Select Date Range"
                editable={false}
                shouldDisableDate={
                  allowedRange ? allowedRange(fixedNewDate(earliestAllowedCustomDate), fixedNewDate(latestAllowedCustomDate)) : undefined
                }
                character={dateDisplaySeparator}
                placement="bottomStart"
                style={{ zIndex: 5000 }}
              />
            </div>
          )}
          {/* <button onClick={() => setFilterHelpDialogOpen(true)}>
            <img src="/icons/info-white.svg" alt="Info" className="mx-2 h-6 w-6" />
          </button> */}
          <SelectInput
            value={pageDateRange}
            options={pageDateRangeOptions}
            onChange={handleTimeRangeChange}
            onMenuOpen={handleTimeRangeOpen}
            color={selectColor}
            minWidth={155}
          />
        </div>
      </div>
    </div>
  );
}
