import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { getDowngradeBucket } from '../utils/downgrade-utilities';
import DateFilter from './DateFilter';
import { closePopupAtom } from '../store/timerange';

export default function CompanyHeader({ name, location, probability }: Readonly<{ name: string; location: string; probability: number }>) {
  const navigate = useNavigate();
  const downgradeBucket = getDowngradeBucket(probability);
  const setClosePopup = useSetRecoilState(closePopupAtom);

  return (
    <div
      className="sticky z-10"
      onClick={() => {
        setClosePopup(true);
      }}
    >
      <div className="text-white bg-ow-darker-grey">
        <div className="container px-6 mx-auto py-3">
          <div className="flow-root justify-between items-center container mx-auto">
            <div className="flex float-left text-3xl font-mmc mb-1 max-w-full">
              <div className="truncate min-w-0 font-bold whitespace-pre">{name}, </div>
              <div className="truncate min-w-0 font-normal font-mmcrg mr-3">{location}</div>
            </div>

            <button onClick={() => navigate('/about')}>
              <div
                className={`float-left text-xs mr-3 px-1 min-w-[5rem] text-center py-1.5 mt-1 rounded-lg bg-${downgradeBucket.color} text-${downgradeBucket.textColor}`}
              >
                {downgradeBucket.text}
              </div>
            </button>
            <div className="flex items-center float-right py-1 flex-no-wrap whitespace-no-wrap">
              <div className="text-xs hidden lg:inline-flex pr-2 text-white">Filter charts and news to:</div>
              <div className="text-xs inline-flex lg:hidden pr-1 text-white">Filter to:</div>
              <DateFilter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
