import { useState } from 'react';
import ICompanySummary from '../types/company';
import PortfolioListItem from './PortfolioListItem';
import PortfolioListHeader from './PortfolioListHeader';

function compareNames(companyA: ICompanySummary, companyB: ICompanySummary, sortDirection: string) {
  if (sortDirection === 'DOWN') {
    return companyB.sortable_name.localeCompare(companyA.sortable_name);
  }
  return companyA.sortable_name.localeCompare(companyB.sortable_name);
}

export default function PortfolioList({
  companies,
}: Readonly<{
  companies: ICompanySummary[];
}>) {
  const [sortDirection, setSortDirection] = useState<string>('UP');
  const [sortField, setSortField] = useState<string>('NAME');

  const [dailyOrWeekly, setDailyOrWeekly] = useState<string>('WEEKLY');

  const compareCompanySortOrder = (companyA: ICompanySummary, companyB: ICompanySummary) => {
    if (sortField === 'NAME') {
      return compareNames(companyA, companyB, sortDirection);
    } else if (sortField === 'DOWNGRADE') {
      const sortOrderDifference =
        sortDirection === 'DOWN'
          ? companyA.current_score_sort_order - companyB.current_score_sort_order
          : companyB.current_score_sort_order - companyA.current_score_sort_order;

      if (sortOrderDifference === 0) {
        return compareNames(companyA, companyB, 'UP');
      }
      return sortOrderDifference;
    } else if (sortField === 'SCORE') {
      const scoreDifference =
        sortDirection === 'UP' ? companyA.current_score - companyB.current_score : companyB.current_score - companyA.current_score;

      if (scoreDifference === 0) {
        return compareNames(companyA, companyB, 'UP');
      }
      return scoreDifference;
    } else if (sortField === 'PERCENT_CHANGE') {
      let scoreDifference = 0;
      if (dailyOrWeekly === 'DAILY') {
        scoreDifference =
          sortDirection === 'UP'
            ? companyA.previous_day_percent_change - companyB.previous_day_percent_change
            : companyB.previous_day_percent_change - companyA.previous_day_percent_change;
      } else {
        scoreDifference =
          sortDirection === 'UP'
            ? companyA.previous_week_percent_change - companyB.previous_week_percent_change
            : companyB.previous_week_percent_change - companyA.previous_week_percent_change;
      }
      if (scoreDifference === 0) {
        return compareNames(companyA, companyB, 'UP');
      }
      return scoreDifference;
    }
    console.log('Sort field not yet supported');
    return 0;
  };

  return (
    <div>
      <PortfolioListHeader
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        sortField={sortField}
        setSortField={setSortField}
        dailyOrWeekly={dailyOrWeekly}
        setDailyOrWeekly={setDailyOrWeekly}
      />
      {companies
        // .filter((company) => company.time_series.length > 0)
        .toSorted(compareCompanySortOrder)
        .map((company) => (
          <PortfolioListItem
            key={company.company_code}
            name={company.name}
            location={company.country}
            company_code={company.company_code}
            current_score={company.current_score}
            current_score_color_name={company.current_score_color_name}
            current_score_text={company.current_score_text}
            previous_percent_change={dailyOrWeekly === 'DAILY' ? company.previous_day_percent_change : company.previous_week_percent_change}
          />
        ))}
    </div>
  );
}
