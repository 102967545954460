import Papa from 'papaparse';
import { IImportedCompany, IScoringGridCompany } from '../types/scoring-company';
import { displayCompanyIfNotDeleteMe, parseCompanyCode, parseCompanyName, parseCompanyCountry } from '../utils/bulk-loader-functions';

export const downloadFile = (csv: any, fileName: string) => {
  // Prepend UTF-8 Byte Order Mark (BOM) to help ensure correct encoding in applications like Microsoft Excel
  const csvWithBom = '\uFEFF' + csv;
  const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
};

export const createTemplate = () => {
  const templateData = [
    ['email', 'fcode', 'ticker_symbol', 'user_company', 'country', 'comment'],
    [
      'required -- email address of portfolio we are adding',
      'exact match to fcode',
      'exact match to ticker_symbol',
      'approximate match of company name',
      'optional - country -- only populate if you are sure of country',
      'at least one of fcode, ticker_symbol or user_company must be populated',
    ],
    ['peter.lieder@oliverwyman.com', 'uor', '', '', '', 'fcode match'],
    ['francis.julian@oliverwyman.com', '', 'mmc', '', '', 'ticker symbol match'],
    ['francis.julian@oliverwyman.com', '', '', 'US30231G1022', '', 'ISIN match'],
    ['francis.julian@oliverwyman.com', '', '', 'Apple Creek Acquisition', '', 'Three word match'],
    [
      'francis.julian@oliverwyman.com',
      '',
      '',
      'Apple',
      '',
      'Finds Apple, Inc United States as this is the main company with the most articles',
    ],
    ['francis.julian@oliverwyman.com', '', '', 'Apple', 'Canada', 'The Canadian version of Apple'],
    ['francis.julian@oliverwyman.com', '', '', 'The Boeing Company', '', 'Ignores The and Company'],
    ['francis.julian@oliverwyman.com', '', '', 'Néstle SA', '', 'Ignores accent which is in wrong e; Ignores SA'],
    [
      'francis.julian@oliverwyman.com',
      '',
      '',
      'Spirit Aerospace',
      '',
      'Second suggestion in drop down is Spirit Aerosystems which is probably what we want',
    ],
  ];
  return Papa.unparse(templateData, { quotes: true });
};

export const parseImportFields = (importedCompanies: IImportedCompany[]): IScoringGridCompany[] => {
  // default was a 0 based index which caused problems at the database level
  return importedCompanies?.map((imported_company: IImportedCompany, id: number) => ({
    id: id + 1,
    email: (imported_company.email ?? '').toLocaleLowerCase(),
    fcode: imported_company.fcode ?? '',
    ticker_symbol: imported_company.ticker_symbol ?? '',
    user_company: imported_company.user_company ?? '',
    country: imported_company.country ?? '',
    selected_company: '',
    alternative_companies: [],
    upload_status: '',
  }));
};

const lodashStyleGet = (myArray: any[], index: number) => {
  return myArray.length > index ? myArray[index] ?? '' : '';
};

export const exportCSV = (scoringGrid: IScoringGridCompany[]) => {
  const toExport = scoringGrid.map((scoreGridCompany: IScoringGridCompany) => ({
    id: scoreGridCompany.id,
    email: scoreGridCompany.email,
    fcode: scoreGridCompany.fcode,
    ticker_symbol: scoreGridCompany.ticker_symbol,
    user_company: scoreGridCompany.user_company,
    country: scoreGridCompany.country,
    selected_name: parseCompanyName(scoreGridCompany.selected_company),
    alt1_name: parseCompanyName(displayCompanyIfNotDeleteMe(lodashStyleGet(scoreGridCompany.alternative_companies, 0))),
    alt2_name: parseCompanyName(displayCompanyIfNotDeleteMe(lodashStyleGet(scoreGridCompany.alternative_companies, 1))),
    alt3_name: parseCompanyName(displayCompanyIfNotDeleteMe(lodashStyleGet(scoreGridCompany.alternative_companies, 2))),
    selected_code: parseCompanyCode(scoreGridCompany.selected_company),
    alt1_code: parseCompanyCode(displayCompanyIfNotDeleteMe(lodashStyleGet(scoreGridCompany.alternative_companies, 0))),
    alt2_code: parseCompanyCode(displayCompanyIfNotDeleteMe(lodashStyleGet(scoreGridCompany.alternative_companies, 1))),
    alt3_code: parseCompanyCode(displayCompanyIfNotDeleteMe(lodashStyleGet(scoreGridCompany.alternative_companies, 2))),
    selected_country: parseCompanyCountry(scoreGridCompany.selected_company),
    alt1_country: parseCompanyCountry(displayCompanyIfNotDeleteMe(lodashStyleGet(scoreGridCompany.alternative_companies, 0))),
    alt2_country: parseCompanyCountry(displayCompanyIfNotDeleteMe(lodashStyleGet(scoreGridCompany.alternative_companies, 1))),
    alt3_country: parseCompanyCountry(displayCompanyIfNotDeleteMe(lodashStyleGet(scoreGridCompany.alternative_companies, 2))),
    upload_status: scoreGridCompany.upload_status,
  }));
  return Papa.unparse(toExport, { quotes: true });
};
