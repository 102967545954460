import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState, useResetRecoilState } from 'recoil';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import useApiCaller from '../hooks/use-api-caller';
import { IPrivacyConsentRequest } from '../types/admin';
import {
  isPrivacyNoticeOpenAtom,
  isPrivacyNoticeCheckedAtom,
  isTrialNoticeOpenAtom,
  isTrialNoticeCheckedAtom,
} from '../store/privacy-consent';
import Loading from './Loading';
import { useOktaAuth } from '@okta/okta-react';
import { getUserInfo } from '../utils/user-info';
import { userInfoAtom } from '../store/user-info';
import TrialConsent from './TrialConsent';
import PrivacyConsent from './PrivacyConsent';
export default function FtuConsent() {
  const [showPrivacyNotice, setShowPrivacyNotice] = useRecoilState(isPrivacyNoticeOpenAtom);
  const [showTrialNotice, setShowTrialNotice] = useRecoilState(isTrialNoticeOpenAtom);
  const [isPrivacyChecked] = useRecoilState(isPrivacyNoticeCheckedAtom);
  const [isTrialChecked] = useRecoilState(isTrialNoticeCheckedAtom);
  const [loading, setLoading] = useState(true);
  const apiCaller = useApiCaller();
  const { authState, oktaAuth } = useOktaAuth();
  const isAuthenticated = authState?.isAuthenticated;
  const [showDatabaseError, setShowDatabaseError] = useState(false);
  const setUserInfo = useSetRecoilState(userInfoAtom);
  const resetUserInfo = useResetRecoilState(userInfoAtom);

  const initConsentStatus = async () => {
    try {
      const localUserInfo = await getUserInfo();
      const validUserResponse = await apiCaller.validateUserWithInfo(localUserInfo);
      setUserInfo({
        ...localUserInfo,
        subscriptions: validUserResponse.subscriptions,
        email_delivery_rate: validUserResponse.email_delivery_rate,
      });
      setShowPrivacyNotice(!validUserResponse.accepted_privacy_consent);
      setShowTrialNotice(!validUserResponse.trial_ok);

      // log out if the token is not allowed
      //   this occurs when the user has logged out and added the token to the black list
      //   and then uses some back door attack like using cURL to access the same token
      if (!validUserResponse.valid_token) {
        handleLogout();
      }
    } catch (err) {
      setShowDatabaseError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleConsentAccept = () => {
    const newConsent: IPrivacyConsentRequest = {
      accepted_privacy_consent: showPrivacyNotice ? isPrivacyChecked : null,
      accepted_trial_consent: showTrialNotice && !showPrivacyNotice ? isTrialChecked : null,
    };

    apiCaller.savePrivacyConsent(newConsent).then(() => {
      if (showTrialNotice && !showPrivacyNotice) {
        // completed second step
        console.log('Saved second step');
        setShowPrivacyNotice(false);
        setShowTrialNotice(false);
      } else {
        //completed first step
        console.log('Saved first step');
        setShowPrivacyNotice(false);
      }
    });
  };

  const handleLogout = () => {
    resetUserInfo();
    oktaAuth.signOut();
    localStorage.clear();
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      initConsentStatus();
    }
  }, [!!authState, !authState?.isAuthenticated]);

  return (
    <div>
      {(showTrialNotice || showPrivacyNotice) && isAuthenticated && (
        <div className={`fixed top-0 bottom-0 left-0 right-0 z-[100] px-8 py-10 ${loading ? 'bg-white' : 'bg-black'}`}>
          {loading && (
            <div className="h-full flex justify-center items-center">
              <Loading />
            </div>
          )}
          {!loading && !showDatabaseError && (
            <div className="flex flex-col justify-between overflow-y-auto h-full md:h-auto md:w-1/2 md:mx-auto">
              {showTrialNotice && !showPrivacyNotice && <TrialConsent />}
              {showPrivacyNotice && <PrivacyConsent />}
              <button
                className="w-full bg-white text-black font-mmc p-2 disabled:opacity-60 disabled:cursor-not-allowed"
                disabled={(showPrivacyNotice && !isPrivacyChecked) || (showTrialNotice && !showPrivacyNotice && !isTrialChecked)}
                onClick={handleConsentAccept}
              >
                Continue
              </button>

              <button className="w-full bg-white text-black font-mmc p-2 mt-4" onClick={handleLogout}>
                Decline & Logout
              </button>
            </div>
          )}
          {showDatabaseError && (
            <div>
              <h1 className="font-mmc text-4xl mb-8 text-white text-center">Account issue</h1>
              <p className="text-white text-center email-link">
                <a className="text-white" href="mailto: fss@oliverwyman.com">
                  Click here to let us know about your account issue.
                </a>
              </p>
            </div>
          )}
          <TermsAndConditionsModal />
        </div>
      )}
    </div>
  );
}
