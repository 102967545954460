import { format, formatISO, closestTo, getYear, getMonth } from 'date-fns';
import { IntervalLengthConstants } from '../constants/interval-length-constants';
import moment from 'moment';

// https://stackoverflow.com/questions/18758772/how-do-i-validate-a-date-in-this-format-yyyy-mm-dd-using-jquery
function isValidYYYYMMDD(YYYYMMDD: string) {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!YYYYMMDD.match(regEx)) return false; // Invalid format
  var d = new Date(YYYYMMDD);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === YYYYMMDD;
}

function checkPatternYYYYMMDDTHHMMSS(YYYYMMDDTHHMMSS: string) {
  var regEx = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  const isAMatch = YYYYMMDDTHHMMSS.match(regEx);
  if (!isAMatch) {
    console.log('WARNING: does not match pattern $', YYYYMMDDTHHMMSS, '$');
  }
  return isAMatch;
}

// -----------------------------------------------------------------------------------------
// new Date() replacements:
// https://stackoverflow.com/questions/29174810/javascript-date-timezone-issue
// GOING FORWARD -- do not use new Date(YYYYMMDD) anywhere in app -- see fixedNewDate and getTodaysDate

// this fixes bug where software is right in the UK, but not in other time zones.
//  new Date by default in Javascript takes an implicit parameter to the current time zone
//  new Date('2023-02-01') in the US in the morning will create a a date 2023-01-31
export const fixedNewDate = (dateString: string): Date => {
  if (isValidYYYYMMDD(dateString)) {
    return new Date(`${dateString}T00:00:00`);
  }

  return new Date(dateString);
};

// on the other hand new Date() works as we want
export const getTodaysDate = (): Date => {
  // this new date does not have time zone issues as it return exact date time of today
  //    Example: 2020-01-01T10:30:23
  return new Date();
};
// -----------------------------------------------------------------------------------------

export function formatDateTime(dateTime: string) {
  checkPatternYYYYMMDDTHHMMSS(dateTime);
  const originalDateTime = new Date(dateTime);
  const formattedDate = format(originalDateTime, 'do MMM yyyy');
  const formattedTime = format(originalDateTime, 'HH:mm');
  return `${formattedDate} | ${formattedTime}`;
}

export function formatDateTimeForArticle(dateTime: string) {
  const originalDateTime = new Date(dateTime);
  const formattedDate = format(originalDateTime, 'do MMM yyyy');
  const formattedTime = format(originalDateTime, 'HH:mm');
  if (formattedTime === '00:00') {
    return `${formattedDate}`;
  }

  return `${formattedDate} ${formattedTime}`;
}

export function formatDate(date: string, full: boolean = false) {
  const originalDate = fixedNewDate(date);
  const dateFormat = full ? 'dd MMMM yyyy' : 'dd MMM yy';
  return format(originalDate, dateFormat);
}

export function formatDateSlashes(date: string) {
  const originalDate = fixedNewDate(date);
  return format(originalDate, 'dd/MM/yyyy');
}

export function formatDateForGraphPlotLines(date: string) {
  const originalDate = fixedNewDate(date);
  return format(originalDate, 'dd MMM yy');
}

export function formatISODate(date: Date) {
  return formatISO(date);
}

export const formatDateString = (date: string, interval: number) => {
  const originalDate = fixedNewDate(date);
  let dateFormat: string = 'MMM yy';
  switch (interval) {
    case IntervalLengthConstants.Day:
    case IntervalLengthConstants.Week:
      dateFormat = 'dd MMM yy';
      break;
    case IntervalLengthConstants.Month:
      break;
    case IntervalLengthConstants.Quarter:
    case IntervalLengthConstants.Year:
      dateFormat = 'yyyy';
      break;
  }
  return format(originalDate, dateFormat);
};

export function getClosestBeginningOfIntervalDate(date: string, dates: string[], startOfInterval: Function) {
  const originalDate = fixedNewDate(date);
  const intervalStart = startOfInterval(originalDate);
  const datesToCompareWith = [intervalStart];
  const dateToCompare = closestTo(originalDate, datesToCompareWith) || intervalStart;
  let closestDate = closestTo(
    dateToCompare,
    dates
      .filter(
        (dateString) =>
          getMonth(fixedNewDate(dateString)) === getMonth(dateToCompare) && getYear(fixedNewDate(dateString)) === getYear(dateToCompare)
      )
      .map((dateString) => fixedNewDate(dateString))
  );
  return closestDate || originalDate;
}

// get today's YYYY-MM-DD string
export const getTodayYYYYMMDD = (): string => {
  return moment().format('YYYY-MM-DD');
};

export const getMonthsAgoYYYYMMDD = (monthsToShow: number, latestDate: string) => {
  return moment(latestDate, 'YYYY-MM-DD').subtract(monthsToShow, 'months').format('YYYY-MM-DD');
};

export const getWeeksAgoYYYYMMDD = (weeksToShow: number, latestDate: string) => {
  return moment(latestDate, 'YYYY-MM-DD').subtract(weeksToShow, 'weeks').format('YYYY-MM-DD');
};

// const locale = navigator.language;

// WORKING -- but need to discuss with business how they want to localize dates in detail
// https://stackoverflow.com/questions/73496710/get-string-format-for-date-based-on-locale/73496711#73496711
// function getDateFormatPatternInternal() {
//   const getPatternForPart = (part: Intl.DateTimeFormatPart) => {
//       switch (part.type) {
//           case 'day':
//               return 'd'.repeat(part.value.length);
//           case 'month':
//               return 'M'.repeat(part.value.length);
//           case 'year':
//               return 'y'.repeat(part.value.length);
//           case 'literal':
//               return part.value;
//       }
//   };

//   return new Intl.DateTimeFormat(locale).formatToParts(fixedNewDate('2022-01-01'))
//       .map(getPatternForPart)
//       .join('');
// };
// const formatter = new Intl.DateTimeFormat(locale);

const formatPattern = 'dd-MMM-yyyy'; // getDateFormatPatternInternal();

export const getDateFormatPatternForLocale = () => {
  return formatPattern;
};

export function formatDateForLocale(myDate: Date): string {
  return format(myDate, formatPattern);
}

export function formatYYYYMMDDForLocale(YYYYMMDD: string): string {
  return formatDateForLocale(fixedNewDate(YYYYMMDD));
}
