import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ArticleHeader from '../components/ArticleHeader';
import Container from '../components/Container';
import Loading from '../components/Loading';
import useApiCaller from '../hooks/use-api-caller';
import useCurrentPage from '../hooks/use-set-current-page';
import { IArticleWithDowJones } from '../types/article';
import formatHtmlString from '../utils/format-string';
import FSSError from '../components/FSSError';
import { formatDateTimeForArticle } from '../utils/dates';

export default function Article() {
  const apiCaller = useApiCaller();
  const { articleId } = useParams();
  const currentPage = useCurrentPage();
  const today = new Date();

  const [loading, setLoading] = useState(false);
  const [articleWithDowJones, setArticleWithDowJones] = useState<IArticleWithDowJones>();
  const [articleError, setArticleError] = useState(false);

  const fetchArticle = (articleId: string) => {
    setLoading(true);

    apiCaller
      .getArticleWithDowJones(articleId)
      .then((res) => {
        setArticleWithDowJones(res);
        currentPage.setCurrentPage(`Article - ${res.title} - ${articleId}`);
      })
      .catch(() => {
        setArticleError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (articleId) {
      currentPage.setCurrentPage(`Article - ${articleId}`);
      fetchArticle(articleId);
    }
  }, []);

  return (
    <div className="mb-auto">
      <div className="lg:container lg:mx-auto lg:mb-6 lg:px-6">
        <div className="lg:flex lg:justify-center lg:w-full">
          <div className="lg:w-full lg:bg-white lg:pt-8 lg:px-2">
            {loading && (
              <div className="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white z-50">
                <Loading />
              </div>
            )}
            {articleError && <FSSError />}

            {!loading && articleWithDowJones && (
              <div>
                <ArticleHeader
                  title={articleWithDowJones.title}
                  source_name={articleWithDowJones.source_name}
                  date={formatDateTimeForArticle(articleWithDowJones.publication_datetime)}
                  wordCount={articleWithDowJones.word_count}
                  source_logo={articleWithDowJones.source_logo}
                  byline={articleWithDowJones.byline}
                  language_code={articleWithDowJones.language_code}
                />

                <Container>
                  <div className={`text-sm whitespace-pre-line overflow-x-auto text-gray-500 `}>
                    {parse(formatHtmlString(articleWithDowJones.body))}
                  </div>

                  <div className="text-gray-500 text-xs mt-6">
                    <div className="mt-2 mb-2">{articleWithDowJones.copyright} All rights reserved.</div>
                    <div className="mt-2 mb-2">Document Identifier: {articleId}</div>
                    <div className="mt-2 mb-2">Copyright {today.getFullYear()} Factiva, Inc All rights reserved.</div>
                  </div>
                </Container>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
