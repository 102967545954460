import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import useApiCaller from '../hooks/use-api-caller';
import '../styles/PortfolioListItem.scss';
import { toast } from 'react-toastify';
import SearchName from './SearchName';
import { portfolioCompaniesListAtom } from '../store/portfolio';
import { bulkSelectedCompanyAtom } from '../store/bulk-selected';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { userInfoAtom } from '../store/user-info';

export default function SearchListItem({
  company_code,
  name,
  country,
  in_portfolio,
  searchTerm,
  bulkMode,
}: Readonly<{
  company_code: string;
  name: string;
  country: string;
  in_portfolio: boolean;
  searchTerm: string;
  bulkMode: boolean;
}>) {
  const apiCaller = useApiCaller();

  const [localInPortfolio, setLocalInPortfolio] = useState(in_portfolio);
  const [isHovered, setIsHovered] = useState(false);
  const setPortfolioCompaniesList = useSetRecoilState(portfolioCompaniesListAtom);
  const setBulkSelectedCompany = useSetRecoilState(bulkSelectedCompanyAtom);

  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  function toggleInPortfolio() {
    const origInPortfolio = localInPortfolio;
    setLocalInPortfolio(!localInPortfolio);
    if (!localInPortfolio) {
      apiCaller
        .addToPortfolio(company_code)
        .then(() => {
          // invalidate list of companies if successfully changed
          setPortfolioCompaniesList([]);

          const userMessage = `Added ${name} to portfolio`;
          setUserInfo({ ...userInfo, subscriptions: userInfo.subscriptions + 1 });
          toast.success(userMessage);
        })
        .catch((err) => {
          const userMessage = `Could not add ${name} to portfolio`;
          console.error(userMessage, err);
          toast.error(userMessage);
          //rollback as saving failed
          setLocalInPortfolio(origInPortfolio);
        });
    } else {
      apiCaller
        .removeFromPortfolio(company_code)
        .then(() => {
          // invalidate list of companies if successfully changed
          setPortfolioCompaniesList([]);

          const userMessage = `Removed ${name} from portfolio`;
          setUserInfo({ ...userInfo, subscriptions: userInfo.subscriptions - 1 });
          toast.success(userMessage);
        })
        .catch((err) => {
          const userMessage = `Could not remove ${name} from portfolio`;
          console.error(userMessage, err);
          toast.error(userMessage);
          //rollback as saving failed
          setLocalInPortfolio(origInPortfolio);
        });
    }
  }

  function getToggleInPortfolioImage() {
    if (localInPortfolio) {
      return isHovered ? '/icons/remove_search_selected.svg' : '/icons/remove_search.svg';
    }
    return isHovered ? '/icons/add_search_selected.svg' : '/icons/add_search.svg';
  }

  function getToggleInPortfolioAltText() {
    if (localInPortfolio) {
      return 'remove';
    }
    return 'add';
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-2 mt-2 gap-1 ">
        <div className="max-w-[50%]">
          <div>
            <div className="mr-4">
              <NavLink to={`/company/${company_code}`}>
                <SearchName name={name} searchTerm={searchTerm} />
              </NavLink>
              <p className="text-xs fss-company-country">{country}</p>
            </div>
          </div>
        </div>
        <div className="flex items-center ">
          <div className="min-w-[3rem] m-0 pb-0 text-right">
            {!bulkMode && (
              <button onClick={() => toggleInPortfolio()} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <img src={getToggleInPortfolioImage()} alt={getToggleInPortfolioAltText()} />
              </button>
            )}
            {bulkMode && (
              <button
                onClick={() => setBulkSelectedCompany(`${company_code} | ${name} | ${country}`)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src="/icons/add_search.svg" alt="Set company" />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="border-b border-ow-light-grey"></div>
    </div>
  );
}
