export const getOktaTokenFromLocalStorage = () => {
  const oktaTokenItem = localStorage.getItem('okta-token-storage') || '{}';

  if (oktaTokenItem) {
    const parsedObject = JSON.parse(oktaTokenItem);
    if (parsedObject?.accessToken?.accessToken) return parsedObject.accessToken.accessToken;
  }

  return '';
};
