import { NavLink } from 'react-router-dom';

export default function PortfolioListHeader({
  sortDirection,
  setSortDirection,
  sortField,
  setSortField,
  dailyOrWeekly,
  setDailyOrWeekly,
}: Readonly<{
  sortDirection: string;
  setSortDirection: (direction: string) => void;
  sortField: string;
  setSortField: (field: string) => void;
  dailyOrWeekly: string;
  setDailyOrWeekly: (field: string) => void;
}>) {
  function setSort(field: string, defaultDirection: string) {
    if (sortField !== field) {
      setSortDirection(defaultDirection);
    } else {
      setSortDirection(sortDirection === 'DOWN' ? 'UP' : 'DOWN');
    }
    setSortField(field);
  }
  function toggleDaily() {
    setDailyOrWeekly(dailyOrWeekly === 'DAILY' ? 'WEEKLY' : 'DAILY');
  }

  function getDailyWeeklyHeader(dailyOrWeekly: string) {
    return dailyOrWeekly === 'DAILY' ? 'Daily %' : 'Weekly %';
  }

  return (
    <div>
      {/* when header is on an xs device according to Tailwind, move the titles to a separate line from the sorting */}
      <div className="inline sm:hidden flex justify-between text-xs pb-2 mb-2 font-bold uppercase">
        <div>Name</div>
        <div className="flex justify-center">
          <div className="min-w-[3rem]">Score</div>
          <div className="min-w-[3rem]">
            {' '}
            <div onClick={() => toggleDaily()}>{getDailyWeeklyHeader(dailyOrWeekly)}</div>
          </div>
          <div className="min-w-[2rem]"> Signal</div>
          <div className="min-w-[2rem]"></div>
        </div>
      </div>
      <div className="flex justify-between text-xs border-b border-ow-light-grey pb-2 mb-2">
        <div className="font-bold uppercase">
          <div className="flex">
            <div className="hidden sm:inline">Name</div>
            <div onClick={() => setSort('NAME', 'DOWN')}>
              {sortField === 'NAME' && sortDirection === 'UP' && <img src="/icons/up-arrow.png" alt="Up" className="ml-1 h-4 w-4" />}
              {sortField === 'NAME' && sortDirection === 'DOWN' && <img src="/icons/down-arrow.png" alt="Down" className="ml-1 h-4 w-4" />}
              {sortField !== 'NAME' && <img src="/icons/no-sort.png" alt="No sort" className="ml-1 h-4 w-4" />}
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="min-w-[3rem] sm:min-w-[5rem]">
            <div className="flex justify-center font-bold uppercase">
              <div className="hidden sm:inline">Score</div>
              <div onClick={() => setSort('SCORE', 'DOWN')}>
                {sortField === 'SCORE' && sortDirection === 'UP' && <img src="/icons/up-arrow.png" alt="Up" className="ml-1 h-4 w-4" />}
                {sortField === 'SCORE' && sortDirection === 'DOWN' && (
                  <img src="/icons/down-arrow.png" alt="Down" className="ml-1 h-4 w-4" />
                )}
                {sortField !== 'SCORE' && <img src="/icons/no-sort.png" alt="No sort" className="ml-1 h-4 w-4" />}
              </div>
            </div>
          </div>
          <div className="min-w-[3rem] sm:min-w-[5rem]">
            <div className="flex justify-center text-xs font-bold uppercase">
              <div className="hidden sm:inline">
                <div onClick={() => toggleDaily()}>{getDailyWeeklyHeader(dailyOrWeekly)}</div>
              </div>
              <div onClick={() => setSort('PERCENT_CHANGE', 'DOWN')}>
                {sortField === 'PERCENT_CHANGE' && sortDirection === 'UP' && (
                  <img src="/icons/up-arrow.png" alt="Up" className="ml-1 h-4 w-4" />
                )}
                {sortField === 'PERCENT_CHANGE' && sortDirection === 'DOWN' && (
                  <img src="/icons/down-arrow.png" alt="Down" className="ml-1 h-4 w-4" />
                )}
                {sortField !== 'PERCENT_CHANGE' && <img src="/icons/no-sort.png" alt="No sort" className="ml-1 h-4 w-4" />}
              </div>
            </div>
          </div>
          <div className="min-w-[2rem] sm:min-w-[5.5rem]">
            <div className="flex justify-center font-bold uppercase">
              <div className="hidden sm:inline">Signal</div>
              <div onClick={() => setSort('DOWNGRADE', 'DOWN')}>
                {sortField === 'DOWNGRADE' && sortDirection === 'UP' && <img src="/icons/up-arrow.png" alt="Up" className="ml-1 h-4 w-4" />}
                {sortField === 'DOWNGRADE' && sortDirection === 'DOWN' && (
                  <img src="/icons/down-arrow.png" alt="Down" className="ml-1 h-4 w-4" />
                )}
                {sortField !== 'DOWNGRADE' && <img src="/icons/no-sort.png" alt="No sort" className="ml-1 h-4 w-4" />}
              </div>
            </div>
          </div>
          <div className="min-w-[2rem] sm:min-w-[3rem] text-left">
            <div>
              <NavLink to={`/about`} className="flex justify-end">
                <img src="/icons/info.svg" alt="Info" className="mr-1 h-4 w-4 justify-right" />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
