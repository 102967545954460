import Highcharts from 'highcharts';
import { orderBy } from 'lodash';
import ICompanySummary from '../types/company';

export const createComparableHistoryCompanies = (companies: ICompanySummary[]): ICompanySummary[] => {
  return orderBy(companies, ['current_score', 'name'], ['desc', 'asc']);
};

const adjustScore = (current_score: number): number => {
  const randomNumberBetweenNeg10andPos10 = Math.floor(Math.random() * 21) - 10;
  const newScore = current_score + randomNumberBetweenNeg10andPos10;
  return newScore > 0 && newScore < 100 ? newScore : current_score;
};

// TODO: change API call to get history for each company
//   put in real dates
export const createComparableHistoryDataSeries = (companies: ICompanySummary[]): Highcharts.SeriesOptionsType[] => {
  return companies.map((company: any) => {
    return {
      color: '#000000',
      type: 'line',
      marker: { fillColor: '#000000' },
      name: company.name,
      data: [
        ['2024-08-01', adjustScore(company.current_score)],
        ['2024-08-02', adjustScore(company.current_score)],
        ['2024-08-03', company.current_score],
      ],
    };
  });
};
